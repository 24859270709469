<template>
  <div v-if="_g_IsUserLogged && !_g_User.is_only_authenticated_user">
    <div v-if="_g_UserFeatures.permissions.broker_simulator" id="broker-simulator" class="broker-simulator-layout">
      <h2 id="title-text">Simulador del bróker</h2>
      <div class="mdc-layout-grid__inner">
        <div class="mdc-layout-grid__cell--span-6 left">
          <div class="cartera-1" v-if="this.user_portfolios.length != 0 && this.portfolio_selected.length != 0">
            <SelectOutlineWallet
              id="cartera-element"
              style="border-radius:20px;"
              :label_name="select_items[0].label_name"
              :options="this.user_portfolios"
              :helper_select_permission="select_items[0].helper_select_permission"
              :helper_text_select="this.helper_text_select"
              :default_value="this.portfolio_selected[0].name"
              :component_class="select_items[0].component_class"
              @_emit_method_order_select="_changePortfolioSelected"
            />

          </div>
          <h3 id="first-subtitle" class="text-grey-uppercase">Orden del bróker</h3>
          <div class="row">
            <SelectOutline v-if="stock_options.length != 0" :ref="stock_ref" :component_class="stock_component_class" :label_name="stock_label_name" :options="stock_options" :helper_select_permission="stock_helper_select_permission" :helper_text_select="stock_helper_text_select" @_emit_method_order_select="_getValueFromSelect" />
            <SelectOutline v-for="(item, index) in select_broker_simulator" :key="index" :ref="item.ref" :component_class="item.component_class" :label_name="item.label_name" :options="item.options" :helper_select_permission="item.helper_select_permission" :helper_text_select="item.helper_text_select" @_emit_method_order_select="_getValueFromSelect" />
            <InputTextOutline v-for="(item, index) in input_broker_simulator" :key="'A'+index" :ref="item.ref" :permission="item.permission" :character_counter_permission="item.character_counter_permission" :icon_permission="item.icon_permission" :helper_permission="item.helper_permision" :input_key_to_save_user_values="item.input_key_to_save_user_values" :input_label="item.input_label" :has_focus="item.has_focus" :length="item.length" :input_class_character_counter="item.input_class_character_counter" :icon_class="item.icon_class" :test_field_is_numeric="item.test_field_is_numeric" @_emit_method_order_input="_getValueFromInput" />
          </div>

          <div id="ordenes-relacionadas" class="row">
            <h3 id="second-subtitle" class="relation-order text-grey-uppercase">Ordenes relacionadas</h3>
          </div>
            <div class="relation-orders-wrapper" >
              <Toggle ref="getMaterialExitInput" :component_name="toggle_take_profit_name" :label_name="toggle_take_profit_label" :input_id="toggle_take_profit_id" @_emit_method_check_toggle="_getValueFromToggle" />
              <Toggle ref="_comp_toggle_stoploss" :component_name="toggle_stoploss_name" :label_name="toggle_stoploss_label" :input_id="toggle_stoploss_id" @_emit_method_check_toggle="_getValueFromToggle" />
            </div>

            <div v-show="_g_HandlerToggleViews.broker_simulator_take_profit">
              <div class="row">
                <InputTextOutline placeholder="AAPL" ref="getEmpresaTicketValue" :computed_value="user_values_selected.empresa_ticket" :computed_value_permission="true" :input_key_to_save_user_values="input_empresa_ticket_class" :has_focus="false" :permission="false" :length="16" :icon_permission="false" :icon_class="input_class_icon" :character_counter_permission="false" :test_field_is_numeric="false"/>
                <SelectOutline v-for="(item, index) in select_broker_simulator_take_profit" :key="'B'+index" :ref="item.ref" :component_class="item.component_class" :label_name="item.label_name" :options="item.options" :helper_select_permission="item.helper_select_permission" :helper_text_select="item.helper_text_select" @_emit_method_order_select="_getValueFromSelect" />
                <InputTextOutline v-for="(item, index) in input_broker_simulator_take_profit" :key="'C'+index" :ref="item.ref" :permission="item.permission" :character_counter_permisson="item.character_counter_permission" :icon_permission="item.icon_permission" :helper_permission="item.helper_permision" :input_key_to_save_user_values="item.input_key_to_save_user_values" :input_label="item.input_label" :has_focus="item.has_focus" :length="item.length" :input_class_character_counter="item.input_class_character_counter" :icon_class="item.icon_class" :test_field_is_numeric="item.test_field_is_numeric" @_emit_method_order_input="_getValueFromInput" />
              </div>
            </div>
            <div v-show="_g_HandlerToggleViews.broker_simulator_stoploss" class="border-bottom-stoploss">
              <div class="row">
                <InputTextOutline placeholder="AAPL" ref="getEmpresaTicketValueStoploss" :computed_value="user_values_selected.empresa_ticket" :computed_value_permission="true" :input_key_to_save_user_values="input_empresa_ticket_stoploss_class" :has_focus="false" :permission="false" :length="16" :icon_permission="false" :icon_class="input_class_icon" :character_counter_permission="false" :test_field_is_numeric="false" />
                <SelectOutline v-for="(item, index) in select_broker_simulator_stoploss" :key="'D'+index" :ref="item.ref" :component_class="item.component_class" :label_name="item.label_name" :options="item.options" :helper_select_permission="item.helper_select_permission" :helper_text_select="item.helper_text_select" @_emit_method_order_select="_getValueFromSelect" />
                <InputTextOutline v-for="(item, index) in input_broker_simulator_stoploss" :key="'E'+index" :ref="item.ref" :permission="item.permission" :character_counter_permisson="item.character_counter_permission" :icon_permission="item.icon_permission" :helper_permission="item.helper_permision" :input_key_to_save_user_values="item.input_key_to_save_user_values" :input_label="item.input_label" :has_focus="item.has_focus" :length="item.length" :input_class_character_counter="item.input_class_character_counter" :icon_class="item.icon_class" :test_field_is_numeric="item.test_field_is_numeric" @_emit_method_order_input="_getValueFromInput" />
              </div>
            </div>
          <div class="relation-orders-wrapper" >
            <Toggle ref="_comp_toggle_oco" :component_name="toggle_oco_name" :label_name="toggle_oco_label" :input_id="toggle_oco_id" @_emit_method_check_toggle="_getValueFromToggle" />
          </div>

          <div v-show="_g_HandlerToggleViews.broker_simulator_oco">
            <div class="row">
                <InputTextOutline placeholder="AAPL" ref="getEmpresaTicketValueOco" :computed_value="user_values_selected.empresa_ticket" :computed_value_permission="true" :input_key_to_save_user_values="input_empresa_ticket_oco_class" :has_focus="false" :permission="false" :length="16" :icon_permission="false" :icon_class="input_class_icon" :character_counter_permission="false" :test_field_is_numeric="false" />
                <SelectOutline v-for="(item, index) in select_broker_simulator_oco" :key="'B'+index" :ref="item.ref" :component_class="item.component_class" :label_name="item.label_name" :options="item.options" :helper_select_permission="item.helper_select_permission" :helper_text_select="item.helper_text_select" @_emit_method_order_select="_getValueFromSelect" />
                <InputTextOutline v-for="(item, index) in input_broker_simulator_oco" :key="'C'+index" :ref="item.ref" :permission="item.permission" :character_counter_permisson="item.character_counter_permission" :icon_permission="item.icon_permission" :helper_permission="item.helper_permision" :input_key_to_save_user_values="item.input_key_to_save_user_values" :input_label="item.input_label" :has_focus="item.has_focus" :length="item.length" :input_class_character_counter="item.input_class_character_counter" :icon_class="item.icon_class" :test_field_is_numeric="item.test_field_is_numeric" @_emit_method_order_input="_getValueFromInput" />            
            </div>
          </div>

          <div class="button">
            <Button :button_name="button_name" @_emit_method_button="_postUserOrderDataToDrupalRepository" :button_icon="button_icon_id" :button_key="button_key" :button_icon_permission="false" />
          </div>
        </div>

        <div class="mdc-layout-grid__cell--span-6 right">
          <h3 id="third-subtitle" class="text-grey-uppercase">Historial de órdenes</h3>
          <!--v-if="historical_orders.length < 0" esta linea estaba comentada-->
          <p id="third-subtitle-description" class="historic-data-leyend">Aquí puede ver el historial de tus ordenes. Verás en verde las órdenes que encajan con la metodología Eurekers, y en rojo las órdenes que no cumplen la metodología de Eurekers.</p>
          <div class="row">
            
          <Table :table_header="table_header" :table_body="table_body" @refresh_table="_getUserOrderDatasFromDrupalRepository()"/>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="_g_User.is_only_authenticated_user" class="not-allowed-broker-simulator-container">
    <p class="not-allowed-broker-simulator-title"> SIN ACCESO </p>
    <p class="not-allowed-broker-simulator"> Su plan actual no tiene acceso a esta herramienta. <a href="/app/store" class="link-to-store"> Pulse aquí para cambiar de plan. </a> </p>
  </div>  
  <div id="main-container" class="mdc-layout-grid__cell--span-12 mdc-layout-grid__cell--span-1-phone" v-else style="text-align: center;">
    <div  class="container-image mdc-layout-grid__cell--span-6-desktop mdc-layout-grid__cell--align-middle">      
      <img src="https://s3.eu-west-1.amazonaws.com/assets.eurekers.com/anonymous/herramientas/main/simulador_broker.jpg">
        <div class="text-center">
          <h3>Simulador broker</h3>
          <p>Con nuestro simulador podrás comprar y vender, realizar estrategias, probar y deshacer como si estuvieras haciendo inversiones completamente reales. Es el complemento formativo práctico ideal.</p>
        </div> 
      <div class="button-container">
        <button class="login-button" @click="_redirectToLogin()">LOGIN</button>
      </div> 
    </div>
  </div>
</template>



<script>
import APICaller from "@/components/codeComponents/APICaller.js";
import SelectOutline from '@/components/helpers/SelectOutline_broker_simulator.vue'
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import Toggle from '@/components/helpers/Toggle.vue';
import Button from '@/components/helpers/Button.vue';
import Table from '@/components/helpers/Table.vue';

import selectBrokerSimulator from './configs/broker-simulator/select_broker_simulator.json';
import selectBrokerSimulatorTakeProfit from './configs/broker-simulator/select_broker_simulator_take_profit.json';
import selectBrokerSimulatorStoploss from './configs/broker-simulator/select_broker_simulator_stoploss.json';
import selectBrokerSimulatorOco from './configs/broker-simulator/select_broker_simulator_oco.json';

import inputBrokerSimulator from './configs/broker-simulator/input_broker_simulator.json';
import inputBrokerSimulatorTakeProfit from './configs/broker-simulator/input_broker_simulator_take_profit.json';
import inputBrokerSimulatorStoploss from './configs/broker-simulator/input_broker_simulator_stoploss.json';
import inputBrokerSimulatorOco from './configs/broker-simulator/input_broker_simulator_oco.json';

import SelectOutlineWallet from "@/components/helpers/SelectOutline_wallet_broker_simulator.vue";
import SelectWallet from "@/components/stockchart/components/configs/select_waiting.json";

import { mapGetters, mapMutations } from "vuex";

import GetStocks from "@/components/codeComponents/GetStocks.js";
export default {
  mixins:[APICaller, GetStocks],
  name: 'BrokerSimulator',
  metaInfo() {
    return {
      title: 'Simulador Broker Eurekers',
      meta:[
        { name: 'description', content: "Esta herramienta permite seleccionar un grupo de tickers y crear un índice que representa una hipotética cartera de inversión, ofrecida por Eurekers"}
      ]
    }
  },
  components: {
    SelectOutline,
    InputTextOutline,
    Button,
    Table,
    Toggle,
    SelectOutlineWallet
  },
  data() {
    return {
      // request data
      historical_orders: {},

      // data init select components
      select_broker_simulator: selectBrokerSimulator,
      select_broker_simulator_take_profit: selectBrokerSimulatorTakeProfit,
      select_broker_simulator_stoploss: selectBrokerSimulatorStoploss,
      select_broker_simulator_oco: selectBrokerSimulatorOco,

      // data init input components
      input_broker_simulator: inputBrokerSimulator,
      input_broker_simulator_take_profit: inputBrokerSimulatorTakeProfit,
      input_broker_simulator_stoploss: inputBrokerSimulatorStoploss,
      input_broker_simulator_oco: inputBrokerSimulatorOco,

      // input filled
      input_empresa_ticket_class: 'input-empresa-ticket',
      input_empresa_ticket_stoploss_class: 'input-empresa-ticket-stoploss',
      input_empresa_ticket_oco_class: 'input_empresa_ticket-oco',
      input_class_icon: 'icon_class',

      // init components
      button: {},
      radio_oco: {},
      formField_oco: {},
      radio_ifdone: {},
      formField_ifdone: {},
      radio_ninguna: {},
      formField_ninguna: {},
      toggle_stop_loss_order: {},
      toggle_take_profit_order: {},
      toggle_oco_order: {},

      // toggle
      toggle_take_profit_name: 'broker_simulator_take_profit',
      toggle_take_profit_label: 'take profit',
      toggle_take_profit_id: 'take-profit-order',

      toggle_stoploss_name: 'broker_simulator_stoploss',
      toggle_stoploss_label: 'stop loss',
      toggle_stoploss_id: 'stoploss-order',

      toggle_oco_name: 'broker_simulator_oco',
      toggle_oco_label: 'oco',
      toggle_oco_id: 'oco-order',

      // button
      button_name: 'Enviar orden',
      button_icon_id: '',
      button_key: 'button_broker_simulator_send_order',
      icon_permission: false,

      // table
      table_header: ['fecha', 'ticker', 'detalle', 'acciones'],
      table_body: [],

      // user selected
      user_values_selected: {
        input_price_value: '',
        input_amount_value: '',
        input_amount_ifdone_take_profit_value: '',
        input_amount_ifdone_stop_loss_value: '',
        input_amount_oco_value: '',
        input_price_ifdone_take_profit_value: '',
        input_price_ifdone_stop_loss_value: '',
        input_price_oco_value: '',
        
        empresa_ticker: '',
        buy_sell: '',
        type_user: '',
        duration: '',  
        
        buy_sell_ifdone_take_profit: '',
        type_ifdone_take_profit: '',
        duration_ifdone_take_profit: '',

        buy_sell_ifdone_stop_loss: '',
        type_ifdone_stop_loss: '',
        duration_ifdone_stop_loss: '',

        buy_sell_oco: '',
        type_oco: '',
        duration_oco: ''
      },

      user_portfolios:[],
      select_items: SelectWallet,
      portfolio_selected:[],
      helper_text_select:"",
      
      stock_ref: "select_empresa_ticker",
      stock_label_name: "Empresa / Ticker",
      stock_options: [],
      stock_component_class: "empresa_ticker",
      stock_helper_select_permission: false,
      stock_helper_text_select: "",
      stock_default_value: "",

      stocks: [],
    };
  },
  computed: {
      ...mapGetters(['_g_HandlerToggleViews', "_g_IsUserLogged", "_g_UserFeatures", "_g_User"]),
  },
  watch: {
    stocks(newVal, oldVal) {
      var waiting_for_stock = setInterval(() => {
        if(this.stocks){
          this._changeStocksOptions();
          clearInterval(waiting_for_stock)
        }
      },200)
    }
  },
  mounted() {
    this._getUserOrderDatasFromDrupalRepository();
    this._getPortfolios();
    this._defaultPortfolio();
    var waiting_for_portfolio = setInterval(()=>{
      if(this.user_portfolios.length != 0 && this.portfolio_selected != undefined && this.portfolio_selected != null){
        this.getStocks();
        clearInterval(waiting_for_portfolio);
      }
    },200)
    var waiting_for_stock = setInterval(() => {
      if(this.stocks && this.stocks.length != 0){
        this._changeStocksOptions();
        clearInterval(waiting_for_stock);
      }
    },200)
  },
  methods: {
    ...mapMutations(["setPortfolioIdSelected"]),
    _redirectToLogin() {
        window.location.href = "/user/login"; // Reemplaza la URL con la que desees redirigir
    },
    _getValueFromSelect(value) {
      if(value[1] == "empresa_ticker"){
        this.empresa_ticker = value[0];
      }else if(value[1] == "buy_sell"){
        this.buy_sell = value[0];
      }else if(value[1] == "type"){
        this.type_user = value[0];
      }else if(value[1] == "duration"){
        this.duration = value[0];
      }else if(value[1] == "duration_oco"){
        this.duration_oco = value[0];
      }else if(value[1] == "buy_sell_oco"){
        this.buy_sell_oco = value[0];
      }else if(value[1] == "type_oco"){
        this.type_oco = value[0];
      }else if(value[1] == "buy_sell_ifdone_take_profit"){
        this.buy_sell_ifdone_take_profit = value[0];
      }else if(value[1] == "type_ifdone_take_profit"){
        this.type_ifdone_take_profit = value[0];
      }else if(value[1] == "duration_ifdone_take_profit"){
        this.duration_ifdone_take_profit = value[0];
      }else if(value[1] == "buy_sell_ifdone_stop_loss"){
        this.buy_sell_ifdone_stop_loss = value[0];
      }else if(value[1] == "type_ifdone_stop_loss"){
        this.type_ifdone_stop_loss = value[0];
      }else if(value[1] == "duration_ifdone_stop_loss"){
        this.duration_ifdone_stop_loss = value[0];
      }

      if(this._g_HandlerToggleViews.broker_simulator_oco){
        this.buy_sell_ifdone_take_profit = "",
        this.type_ifdone_take_profit = "";
        this.duration_ifdone_take_profit = "";
        this.buy_sell_ifdone_stop_loss = "";
        this.type_ifdone_stop_loss = "";
        this.duration_ifdone_stop_loss = "";

      }
    },

    _getValueFromInput(value) {
      if(value[1] == "input_amount_value"){
        this.input_amount_value = value[0];
      }else if(value[1] == "input_price_value"){
        this.input_price_value = value[0]
      }else if(value[1] == "input_amount_ifdone_take_profit_value"){
        this.input_amount_ifdone_take_profit_value = value[0]
      }else if(value[1] == "input_price_ifdone_take_profit_value"){
        this.input_price_ifdone_take_profit_value = value[0]
      }else if(value[1] == "input_amount_ifdone_stop_loss_value"){
        this.input_amount_ifdone_stop_loss_value = value[0]
      }else if(value[1] == "input_price_ifdone_stop_loss_value"){
        this.input_price_ifdone_stop_loss_value = value[0]
      }else if(value[1] == "input_amount_oco_value"){
        this.input_amount_oco_value = value[0]
      }else if(value[1] == "input_price_oco_value"){
        this.input_price_oco_value
      }

      if(this._g_HandlerToggleViews.broker_simulator_oco){
        this.input_price_ifdone_take_profit_value= "",
        this.input_amount_ifdone_take_profit_value = "";
        this.input_price_ifdone_stop_loss_value = "";
        this.input_amount_ifdone_stop_loss_value = "";
      }
    },

    _prepareAllEvents() {
      this._prepareTogglesEvents();
    },

    _changePortfolioSelected: function(value){
      this.portfolio_selected = value[0];
      this.setPortfolioIdSelected(this.portfolio_selected);
      let success = (response) => {               
        this.getStocks();             
      };

      let url = '/api/v1/change-portfolio-invest-preferences/' + this.portfolio_selected;

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('175', 'Error recuperando las preferencias de cartera.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _getValueFromToggle(value){
      if(value[1] == 'broker_simulator_take_profit') {
        this._g_HandlerToggleViews.broker_simulator_oco = false;
        this._g_HandlerToggleViews.broker_simulator_take_profit = value[0];

        this.$store.commit('setHandlerToggleViews', this._g_HandlerToggleViews);
      }
      if(value[1] == 'broker_simulator_stoploss') {
        this._g_HandlerToggleViews.broker_simulator_oco = false;
        this._g_HandlerToggleViews.broker_simulator_stoploss = value[0];
        
        this.$store.commit('setHandlerToggleViews', this._g_HandlerToggleViews);
      }
      if(value[1] == 'broker_simulator_oco') {
        this._g_HandlerToggleViews.broker_simulator_stoploss = false;
        this._g_HandlerToggleViews.broker_simulator_take_profit = false;
        this._g_HandlerToggleViews.broker_simulator_oco = value[0];

        this.$store.commit('setHandlerToggleViews', this._g_HandlerToggleViews);
      }
    },

    _getUserOrderDatasFromDrupalRepository() {
      let success = response =>{
        this.table_body = response.data;
      }

      let url = '/api/frontend/v1/broker-simulator/load'

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('176', 'Error cargando simulador de broker.');

      this._getAPICall(url, successHandler, failureHandler);
    },
    _postUserOrderDataToDrupalRepository() {
      var params = new URLSearchParams();
      params.append('asset', this.empresa_ticker);
      params.append('buysell', this.buy_sell);
      params.append('duration', this.duration);
      params.append('type', this.type_user);
      params.append('assets', this.input_amount_value);
      params.append('price', this.input_price_value);

      if(this._g_HandlerToggleViews.broker_simulator_take_profit){
        params.append('takeprofit[buysell]', this.buy_sell_ifdone_take_profit);
        params.append('takeprofit[duration]', this.duration_ifdone_take_profit);
        params.append('takeprofit[type]', this.type_ifdone_take_profit);
        params.append('takeprofit[assets]', this.input_amount_ifdone_take_profit_value);
        params.append('takeprofit[price]', this.input_price_ifdone_take_profit_value);
      }

      if(this._g_HandlerToggleViews.broker_simulator_stoploss){
        params.append('stoploss[buysell]', this.buy_sell_ifdone_stop_loss);
        params.append('stoploss[duration]', this.duration_ifdone_stop_loss);
        params.append('stoploss[type]', this.type_ifdone_stop_loss);
        params.append('stoploss[assets]', this.input_amount_ifdone_stop_loss_value);
        params.append('stoploss[price]', this.input_price_ifdone_stop_loss_value);
      }

      if(this._g_HandlerToggleViews.broker_simulator_oco){
        params.append('oco[buysell]', this.buy_sell_oco);
        params.append('oco[duration]', this.duration_oco);
        params.append('oco[type]', this.type_oco);
        params.append('oco[assets]', this.input_amount_oco_value);
        params.append('oco[price]', this.input_price_oco_value);
      }

      let success = response=>{
        this.table_body = response.data;
      }

      let url = '/api/frontend/v1/broker-simulator'

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('293', 'Error en el guardado.');

      this._postAPICall(url, params, successHandler, failureHandler);
      
      var post = this.user_values_selected
    },


    _getPortfolios: function(){
      let success = (response) => {                
        response.data.forEach(element => {
          this.user_portfolios.push({"name": element.idc, "value": element.name});
        });
      };

      let url = '/api/v1/portfolio/list';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('177', 'Error lista de carteras.');

      this._getAPICall(url, successHandler, failureHandler);

    },

    _defaultPortfolio: function(){
      let success = (response) => {               
        this.portfolio_selected.push({"name": response.data.idc, "value": response.data.name});
      };
      
      let url = '/api/v1/portfolio-invest-preferences';

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('178', 'Error preferencias de inversión.');

      this._getAPICall(url, successHandler, failureHandler);
    },

    _changeStocksOptions: function(){
      this.stock_options = [];
      for(let i = 0; i < this.stocks.length; i++){
        this.stock_options.push({"name": this.stocks[i].ticker, "value": this.stocks[i].ticker})
      }
    },
  },
}
</script>



<style lang="scss" scoped>

  $color: #fff;
  $color_dark: var(--color-text-primary);
  $color_grey: var(--color-text-secondary);
  $color_red: #B00020;
  $color_green: green;
  $back_dark: #151929;
  $back_light: #f0f0f0;
  $gradient_colors_list: #fafafa, #eaeaea;

  .broker-simulator-layout {
    padding: 0 60px;
  }
  
  #broker-simulator {
    background-color: var(--color-background);
  }

  html{
    overflow-y: scroll;
  }

  h2 {
    color: $color_dark;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: .15px;
    margin: 0;
    padding: 80px 0 34px;
  }

  .text-grey-uppercase {
    color: $color_grey;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-transform: uppercase;
    margin-bottom: 15px;}

  .historic-data-leyend {
    margin: 20px 0;
    color: $color_dark;
    line-height: 20px;
    letter-spacing: 0.25px;
    font-size: 14px;}

  

  .border-bottom-stoploss {
    border-top: 1px solid #d0d0d0;
    padding-top: 16px;}

  .color-green {
    color: $color_green;}

  .mdc-data-table__cell.align-left {
    text-align: left;}

  .relation-orders-wrapper {
    display: block;
    margin-bottom: 0;
    .mdc-switch-wrapper {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  
  .broker-history-not-correct {
    background: #ffdada;}

  .select-container-size,
  .input-container-size {
    margin-bottom: 22px;}
  .button {
    margin-top: 50px;
    margin-bottom: 20px;}
  .data-table {
    width: 100%;}

  @media (max-width: 599px){
    .mdc-layout-grid__cell {
      margin: 0;}
    .select-container-size {
      flex: 0 0 48%;
      max-width: 100%;}
    .input-container-size {
      flex: 0 0 48%;
      max-width: 100%;}  
  }
  @media (min-width: 600px) and (max-width: 839px) {
    .mdc-layout-grid__cell--span-6.left {
      grid-column-end: span 8;
      margin: 0;}
    .mdc-layout-grid__cell--span-6.right {
      grid-column-end: span 8;
      margin: 0;}
    .select-container-size {
      flex: 0 0 48%;
      max-width: 100%;}
    .input-container-size {
      flex: 0 0 48%;
      max-width: 100%;}
    .row {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      justify-content: space-between;}
    .relation-orders-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      .mdc-switch-wrapper {
        width: 48%;
        font-size: 14px;
        line-height: 26px;
        .take-profit-order,
        .stop-loss-order,
        .oco-order {
          vertical-align: middle;
        }
        label {
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
  }
  @media (min-width: 840px){
    .mdc-layout-grid__cell--span-6.left {
      margin: 0 36px 0 0;}
    .mdc-layout-grid__cell--span-6.right {
      margin-right: 36px;}
    .select-container-size {
      flex: 0 0 48%;
      max-width: 100%;}
    .input-container-size {
      flex: 0 0 48%;
      max-width: 100%;}
    .row {
      display: flex;
      flex-wrap: wrap;
      flex: 1 1 auto;
      justify-content: space-between;}
    
    .relation-orders-wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px;
      .mdc-switch-wrapper {
        width: 48%;
        font-size: 14px;
        line-height: 26px;
        .take-profit-order,
        .stop-loss-order,
        .oco-order {
          vertical-align: middle;
        }
        label {
          margin-left: 10px;
          vertical-align: middle;
        }
      }
    }
    
  }

  .iframe-container{
    height:100% !important;
  }

  .container-image{
    /* Aplica un margen automático para centrar el div horizontalmente */
    margin: 0 auto;
    /* Ajusta el ancho máximo del contenedor */
    max-width: 800px;
    max-height: auto;
    
  }



  .container-image img{
    max-width: 500px;   
    max-height: 800px;
    margin: 20px;
    border-radius: 5px;
  }

  @media screen and (min-width: 768px) {
    .container-image img {
      width: 500px;
    }
  }

  .text-center {
    text-align: center;
    margin: 20px;
  }

  .container-image p {    
    text-align: justify;
  }

  .login-button{
    color: white;
    background-color: #00add8;
    border-radius: 20px;
    cursor: pointer;
    padding: 10px;
    text-decoration: none;
    border: none;
    outline: none;
    width: 150px;
    margin-top: 20px;
    font-size: 14px;
  }
  .login-button:hover{
    background-color: #00bdec;
  }

    .not-allowed-broker-simulator-container{
      text-align: center;
      margin-top: 50px;
    }
  
    .link-to-store{
      color: #35add8 !important;
    }

    .not-allowed-broker-simulator-title{
      color: #FF9B00;
      font-weight: bold;
    }
</style>
